import './App.css';
import { 
  BrowserRouter as Router, 
  Routes, 
  Route
} from 'react-router-dom';
import Build from './components/Quote';
import '@stripe/stripe-js';
import { useEffect } from 'react';
import TagManager from "react-gtm-module";

function App() {

  TagManager.initialize({'gtmId': "GTM-MD88S8R"})

  TagManager.dataLayer({
      gtmId: "GTM-MD88S8R",
      dataLayer: {
          event: 'pageview',
      }
  });

  useEffect(() => {
    window.addEventListener('beforeunload', localStorage.clear())
   
    return () => {
    window.removeEventListener('beforeunload', localStorage.clear())
  };
  }, []);
  return (
    <Router>
      <Routes>
        <Route path='/' element={
            <Build />
          } 
        />
      </Routes>
    </Router>
  );
}

export default App;
