export const FinanceData = {
    "Lenders":[
    {
        "Climate First Bank":
            [
                {
                    "product": "Climate First 30 Year 7.5% APR",
                    "term": 30,
                    "apr": 7.5,
                    "fee": 0,
                    "type": "Loan"
                },

            ]
    },
    // {
    //     "Goodleap": 
    //         [
    //             {
    //                 "product": "Goodleap 25 Year 10.99% APR 7.74% Fee",
    //                 "term": 25,
    //                 "apr": 10.99,
    //                 "fee": 7.74,
    //                 "type": "Loan"
    //             },
    //         ]
    // },
    // {
    //     "Lightreach": 
    //         [
    //             {
    //                 "product": "Lightreach 25 Year Lease 0% Fee",
    //                 "term": 25,
    //                 "apr": 0,
    //                 "fee": 0,
    //                 "type": "Lease"
    //             },
    //         ]
    // },
    // {
    //     "Dividend": 
    //         [
    //             {
    //                 "product": "Dividend 25 Year 32.4% Fee",
    //                 "term": 25,
    //                 "apr": 0,
    //                 "fee": 32.4,
    //                 "type": "Loan"
    //             },
    //             {
    //                 "product": "Dividend 15 Year 8.99% APR",
    //                 "term": 15,
    //                 "apr": 8.99,
    //                 "fee": 0,
    //                 "type": "Loan"
    //             },
    //         ]
    // },
]}