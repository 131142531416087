import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, CircularProgress } from '@mui/material';
import styled from 'styled-components';

// MUI Styled Components
const StyledDialog = styled(Dialog)`
  & .MuiDialog-paper {
    border-radius: 10px;
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  font-size: 1.2rem;
  font-weight: bold;
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 20px;
  font-size: 1rem;
  color: #333;
`;

const StyledDialogActions = styled(DialogActions)`
  justify-content: center;
  padding-bottom: 16px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  max-width: 300px; /* Ensure the buttons are aligned in two columns */
`;

const ConfirmButton = styled(Button)`
  background-color: #007bff !important;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  &:hover {
    background-color: #0056b3 !important;
  }
`;

const CancelButton = styled(Button)`
  border-color: #6c757d !important;
  color: #6c757d !important;
  width: 100%;
  &:hover {
    background-color: rgba(108, 117, 125, 0.1) !important;
  }
`;

const ClosePopup = ({
    setIsPopupOpen,
    showConfirmation,
    setShowConfirmation,
    resetDesignState,
}) => {
    const [loading, setLoading] = useState(false); 

    const handleConfirmClose = async () => {
        setLoading(true); // Start loading
        try {
        //   await createDesign(projectId);
          resetDesignState();
          setIsPopupOpen(false);
        } catch (error) {
          console.error('Error resetting design:', error);
        } finally {
          setLoading(false); // End loading
          setShowConfirmation(false); // Close confirmation dialog
        }
    };

    const handleCancelClose = () => {
        // User cancels the action, so just close the confirmation modal
        setShowConfirmation(false);
    };

  return (
    <>
      {/* Confirmation Dialog */}
      <StyledDialog
        open={showConfirmation}
        onClose={handleCancelClose}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <StyledDialogTitle id="confirm-dialog-title">
          Are you sure you want to go back and edit your system?
        </StyledDialogTitle>
        <StyledDialogContent>
          Your design will have to be regenerated if you exit this page.
        </StyledDialogContent>
        <StyledDialogActions>
          <ButtonGroup>
            <ConfirmButton
              onClick={handleConfirmClose}
              variant="contained"
              disabled={loading} // Disable the button while loading
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : 'Confirm'}
            </ConfirmButton>
            <CancelButton onClick={handleCancelClose} variant="outlined">
              Cancel
            </CancelButton>
          </ButtonGroup>
        </StyledDialogActions>
      </StyledDialog>
    </>
  );
};

export default ClosePopup;
