import axios from 'axios';
import React, { useCallback, useEffect, useRef } from 'react'
import { IconContext } from 'react-icons'
import TagManager from "react-gtm-module";
import { 
    ButtonContainer, 
    ErrorText, 
    NextButton, 
    ProductListContainer, 
    QuoteAddressWrapper, 
    QuoteAppendedText, 
    QuoteAutocomplete, 
    QuoteGrid, 
    QuoteHeader, 
    QuoteHeader2, 
    QuoteHeader3, 
    QuoteInnerWrapper, 
    QuoteInput, 
    QuoteInputDeleteIcon, 
    QuoteInputLocateIcon, 
    QuoteInputTitle, 
    QuoteInputWrapper, 
    RegistrationErrorWrapper,
} from './QuoteElements'
import { Oval } from 'react-loading-icons';
import products from './ProductData';
import Product from './Product';
import videoSrc from '../../images/lunex_model/static_direct.mp4';
import ModelVideo from './ModelVideo';

const Locate = ({
    formState,
    setFormState,
    initialFormState,
    section,
    setSection,
    nextButton,
    updateFormControl,
    submitting,
    customerErrMesg,
    setCustomerErrMesg,
    loadingTexts,
    loadingTextIndex,
    hardware,
    updateHardware,
    updateSolutions
}) => {

  const onPlaceSelected = useRef(() => undefined);

  const validStates = [
    'FL',
    'MA',
    'RI',
    'CT',
    'CO'
  ]
  
  const updateFormControlAddy = useCallback((event) => {
    setCustomerErrMesg('');
    if (!event || !event.address_components) return;

    let zip = '';
    let state = '';

    event.address_components.forEach(component => {
      if (component.types.includes('postal_code')) {
        zip = component.short_name;
      }
      if (component.types.includes('administrative_area_level_1')) {
        state = component.short_name;
      }
    });

    const updatedFormState = { ...formState, zip, address: event.formatted_address, state };
    setFormState(updatedFormState);
  }, [formState, setFormState, setCustomerErrMesg]);

  const handleLocate = async () => {
    try{
      navigator.geolocation.getCurrentPosition(async function(position) {
        const url = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + position.coords.latitude + ',' + position.coords.longitude + '&key=' + process.env.REACT_APP_GOOGLE_MAPS_API

        const result = await axios.get(url);

        updateFormControlAddy(result.data.results[0]); 

      });
    }
    catch(error){
      console.log("Error encounterd while getting location ", error);
    }
  }

  const handleDelete = () => {
    setCustomerErrMesg('');
    const updatedFormState = { ...formState };
    updatedFormState['address'] = '';
    setFormState(updatedFormState);
  }
  useEffect(() => {
    onPlaceSelected.current = (place) => {
      updateFormControlAddy(place[0]);
    };
  }, [updateFormControlAddy]);

  const nextButtonLocate = () =>  {
    TagManager.dataLayer({
      gtmId: "GTM-MD88S8R",
      dataLayer: {
        event: 'locate_next_clicked'
      }
    });
    if(!validStates.includes(formState.state))
    {
      setCustomerErrMesg("Sorry, we are not available in your state yet");
      TagManager.dataLayer({
        gtmId: "GTM-MD88S8R",
        dataLayer: {
          event: 'locate_next_clicked_invalid_state'
        }
      });
    }
    else{
      nextButton();
      setCustomerErrMesg("");
    }
  }

  const handleKeyPress = (e) => {
    if(e.key === 'Enter' || e.which === 13){
      nextButtonLocate();
     }
  }

  const handleInputChange = (e) => {
    const updatedFormState = { ...formState, address: e.target.value };
    setFormState(updatedFormState);
  };

  const toggleSelect = (id) => {
    // console.log("id", id);
    updateSolutions(id);
  };

  return (
    <QuoteGrid>
      <ModelVideo videoSrc={videoSrc} playbackSpeed={0.4}/>
      <QuoteInnerWrapper>
        <QuoteHeader>Reimagine Your Power</QuoteHeader>
        <QuoteHeader3>Enter Home Details</QuoteHeader3>
        <QuoteInputWrapper>
        <QuoteInputTitle htmlFor='address'>Home Address</QuoteInputTitle>
        <QuoteAddressWrapper>
          <IconContext.Provider
              value={{ size: '35px' }}
          >
          {formState.address === '' ? (
              <>
                <QuoteAutocomplete 
                  apiKey="AIzaSyAOAiAN5-83Fjxb45YLTjxGtBkAVaY1_cI"
                  onPlaceSelected={(...args) => onPlaceSelected.current(args)}
                  options={{
                    types: ["address"],
                    componentRestrictions: { country: "us" },
                  }}
                  id='address'
                  defaultValue={formState.address} 
                />
                <QuoteInputLocateIcon onClick={handleLocate} />
              </>
            ) : (
              <>
                <QuoteInput 
                  type='text' 
                  id='address' 
                  value={formState.address} 
                  onChange={handleInputChange} 
                />
                <QuoteInputDeleteIcon onClick={handleDelete} />
              </>
            )}
          
          </IconContext.Provider>
        </QuoteAddressWrapper>
        </QuoteInputWrapper>
        <br />
        <QuoteInputWrapper>
            <QuoteInputTitle htmlFor='electricBill'>Average Electric Bill</QuoteInputTitle>
            <QuoteAddressWrapper>
              <QuoteInput type='text' id='electricBill' value={formState.electricBill} onChange={(updateFormControl)} onKeyPress={handleKeyPress}/>
              <QuoteAppendedText>/ mo</QuoteAppendedText>
            </QuoteAddressWrapper>
        </QuoteInputWrapper>
        <br />
        <QuoteHeader2>Select Your Energy Solutions</QuoteHeader2>
        <QuoteHeader3>Customize your energy setup by choosing from our range of solar panels, battery backups, and EV chargers.</QuoteHeader3>
        <ProductListContainer>
          {products.map((product) => (
            <Product 
              key={product.id} 
              product={product}
              solution={hardware.solutions.includes(product.id)}
              toggleSelect={toggleSelect}
              />
          ))}
        </ProductListContainer>
        {!submitting&&
          <ButtonContainer>
            <NextButton onClick={nextButtonLocate} disabled={!formState.address || !formState.electricBill || hardware.solutions.length <= 0} >Next</NextButton>
          </ButtonContainer>
        }
        {submitting &&
          <>
            <ButtonContainer>
              <NextButton>
                <Oval height='1.5rem'/>
              </NextButton>
            </ButtonContainer>
            <RegistrationErrorWrapper>
              {loadingTexts[loadingTextIndex]}
            </RegistrationErrorWrapper>

          </>
        }
        {customerErrMesg &&
          <RegistrationErrorWrapper>
              <ErrorText>{customerErrMesg}</ErrorText>
          </RegistrationErrorWrapper>
        }
          
      </QuoteInnerWrapper>
    </QuoteGrid>
    )
}

export default Locate