import React, { useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import styled from 'styled-components';

const StyledAccordion = styled(Accordion)`
  width: 100%;
  margin: 10px 0;
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f5f5; /* Directly setting the background color */
  padding: 10px 20px;
  border-radius: 4px;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Title = styled(Typography)`
  font-size: 1.1rem !important;
  font-weight: bold;
`;

const Amount = styled(Typography)`
  font-size: 1.2rem;
  font-weight: bold;
  padding-right: 10px;
  color: #333;
`;

const Header = styled.div`
  background: linear-gradient(135deg, #1976d2, #004ba0);
  color: white;
  padding: 15px 20px;
  border-radius: 4px 4px 0 0;
  font-size: 1.5rem;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 10px rgba(25, 118, 210, 0.5); /* Subtle glowing effect */
  transition: box-shadow 0.3s ease-in-out;
`;

const CustomAccordion = ({ headerTitle, title, amount, children, open }) => {
  const [expanded, setExpanded] = useState(open ? true : false);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <Header>{headerTitle}</Header>
      <StyledAccordion expanded onChange={handleChange}>
        {/* <StyledAccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <TitleContainer>
            <Title>{title}</Title>
            <Amount>{amount}</Amount>
          </TitleContainer>
        </StyledAccordionSummary> */}
        <AccordionDetails>{children}</AccordionDetails>
      </StyledAccordion>
    </>
  );
};

export default CustomAccordion;
