import React, { useState } from 'react';

const OTPInput = ({ length = 6, setOTP }) => {
    const [otpArray, setOtpArray] = useState(new Array(length).fill(''));
  
    const handleChange = (element, index) => {
      const value = element.value.replace(/[^0-9]/g, ''); // Allow only numbers
      const newOtp = [...otpArray];
      newOtp[index] = value; // Update the current index with the new value
      setOtpArray(newOtp);
      setOTP(newOtp.join('')); // Update parent OTP state
  
      // Move to the next input field if the value is not empty
      if (value && index < length - 1 && element.nextSibling) {
        element.nextSibling.focus();
      }
    };
  
    const handleKeyDown = (e, index) => {
      if (e.key === 'Backspace') {
        e.preventDefault();
        const newOtp = [...otpArray];
        if (otpArray[index] === '') {
          // Move to the previous input field if the current one is empty
          if (index > 0) {
            newOtp[index - 1] = '';
            e.target.previousSibling.focus();
          }
        } else {
          // Clear the current input field
          newOtp[index] = '';
        }
        setOtpArray(newOtp);
        setOTP(newOtp.join('')); // Update parent OTP state
      }
    };
  
    const handlePaste = (e) => {
      e.preventDefault();
      const pastedData = e.clipboardData
        .getData('text')
        .replace(/[^0-9]/g, '')
        .slice(0, length)
        .split('');
      const newOtp = [...otpArray];
      pastedData.forEach((value, i) => {
        if (i < length) newOtp[i] = value;
      });
      setOtpArray(newOtp);
      setOTP(newOtp.join(''));
    };
  
    return (
      <div style={{ display: 'flex', justifyContent: 'center', gap: '5px' }}>
        {otpArray.map((digit, index) => (
          <input
            key={index}
            type="text"
            inputMode="numeric"
            pattern="[0-9]*"
            maxLength="1"
            value={digit}
            onChange={(e) => handleChange(e.target, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            onPaste={handlePaste}
            style={{
              width: 'calc(100% / 8)', // Responsive width
              height: 'calc(100% / 8)', // Matching height
              maxWidth: '40px', // Ensure inputs don't grow too large
              maxHeight: '40px',
              textAlign: 'center',
              fontSize: '20px',
              border: '1px solid #ccc',
              borderRadius: '4px',
            }}
          />
        ))}
      </div>
    );
  };
  
  export default OTPInput;
  
  
