import React, { useEffect, useRef } from 'react';
import Lottie from 'lottie-react';
import styled from 'styled-components';
import throttle from 'lodash.throttle';
import animationData from '../../images/lunex_model/fullmotion.json';
import animationDataFixed from '../../images/lunex_model/fullmotion.json';

const AnimationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
`;

const LottieStyled = styled(Lottie)`
  flex-shrink: 0;
  width: auto;
  height: 100%;
`;

const ScrollAnimation = ({ scrollContainerRef, useScroll = false }) => {
  const lottieRef = useRef();

  useEffect(() => {
    if (!useScroll || !scrollContainerRef?.current || !lottieRef.current) return;

    const handleScroll = throttle(() => {
        const container = scrollContainerRef.current;
        const scrollTop = container.scrollTop;
        const maxScrollTop = container.scrollHeight - container.clientHeight;
        const scrollPercent = scrollTop / maxScrollTop;
  
        const instance = lottieRef.current;
  
        const totalFrames = instance.getDuration(true);
  
        const frame = totalFrames * scrollPercent;
  
        instance.goToAndStop(frame, true);
      }, 50);

    const container = scrollContainerRef.current;
    container.addEventListener('scroll', handleScroll);

    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, [scrollContainerRef, useScroll]);


  return (
    <AnimationWrapper>
      <LottieStyled
        lottieRef={lottieRef}
        animationData={useScroll ? animationData : animationDataFixed}
        loop={!useScroll}
        autoplay={!useScroll}
        rendererSettings={{
          preserveAspectRatio: 'xMidYMid slice',
        }}
      />
    </AnimationWrapper>
  );
};

export default ScrollAnimation;
