export const LeaseData = [
    {
        "State": "FL",
        "Rate": .14,
    },
    {
        "State": "MA",
        "Rate": .25,
    },
    {
        "State": "CT",
        "Rate": .25,
    },
    {
        "State": "RI",
        "Rate": .25,
    },
]