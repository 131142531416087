import solar from '../../images/solar.JPG';
import battery from '../../images/Powerwall3.jpg';
import evCharger from '../../images/Wallbox.jpg';

const products = [
    {
        id: 1,
        category: 'solar',
        image: solar,
        imageAlt: 'Solar Panels',
        title: 'Solar Panels',
        subtitle: 'High-efficiency solar panels for your home',
    },
    {
      id: 2,
      category: 'battery',
      image: battery,
      alt: 'Battery Backup Solutions',
      title: 'Battery Backup Solutions',
      subtitle: 'Keep your home powered during outages with our top-tier battery systems',
    },
    {
      id: 3,
      category: 'other',
      image: evCharger,
      alt: 'EV Chargers and Other Services',
      title: 'EV Chargers',
      subtitle: "From EV chargers to energy management systems, we've got you covered",
    },
  ];
  
  export default products;