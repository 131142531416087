import React, { useState } from 'react';
import styled from 'styled-components';
import { Typography, Link, Select, MenuItem } from '@mui/material';

const Container = styled.div`
  margin: 20px 0;
  padding-bottom: 50px;
`;


const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  height: 200px; /* Fixed height to show top 40% of the image */
`;

const HardwareImage = styled.img`
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

const InfoSection = styled.div`
  display: flex;
  justify-content: ${({ columns }) => (columns ? 'space-between' : 'center')};
  flex-wrap: wrap;
  margin-top: 20px;
`;

const InfoColumn = styled.div`
  width: ${({ columns }) => (columns ? '48%' : '100%')};
  text-align: center;
`;

const SpecSheetLink = styled(Link)`
  display: block;
  text-align: center;
  padding-top: 10px;
  margin: 20px 0;
`;

const ManufacturerLocation = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const ManufacturerImage = styled.img`
  margin-top: 10px;
  width: 60px;
  height: auto;
`;

const Dropdown = styled(Select)`
  width: 100%;
  margin: 0 auto 20px auto;
  display: block;
`;

const FullScreenWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ImageContainer = styled.div`
display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  height: 95%;
  background: #000;
  position: relative;
`;

const FullScreenImage = styled.img`
    margin: 0 auto;
    height: auto;
    width: auto;
    border-radius: 8px;
    max-height: 100%;
    max-width: 100%;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: black;
  border: none;
  color: white;
  font-size: 1.5em;
  cursor: pointer;
`;

const HardwareDetails = ({ title, options, selectedOption, setSelectedOption }) => {
  const { image, column1, column2, 'Spec Sheet': specSheet, manufacturerLocationImage } = selectedOption;
  const [isFullScreen, setIsFullScreen] = useState(false);

  const renderDetails = (details) => {
    return Object.entries(details).map(([key, value]) => (
      <Typography key={key}>{`${key}: ${value}`}</Typography>
    ));
  };

  const handleImageClick = () => {
    setIsFullScreen(true);
  };

  const handleCloseFullScreen = () => {
    setIsFullScreen(false);
  };

  return (
    <Container>
      <Dropdown
        value={selectedOption.name}
        onChange={(e) => setSelectedOption(options.find(option => option.name === e.target.value))}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option.name}>{option.name}</MenuItem>
        ))}
      </Dropdown>
      <ImageWrapper onClick={handleImageClick}>
        <HardwareImage src={image} alt={selectedOption.name} />
      </ImageWrapper>
      {isFullScreen && (
        <FullScreenWrapper>
            <ImageContainer>
                <FullScreenImage src={image} alt={selectedOption.name} />
                <CloseButton onClick={handleCloseFullScreen}>&times;</CloseButton>
            </ImageContainer>
        </FullScreenWrapper>
      )}
      <InfoSection columns={column1 && column2}>
        {column1 && (
          <InfoColumn columns>
            {renderDetails(column1)}
          </InfoColumn>
        )}
        {column2 && (
          <InfoColumn columns>
            {renderDetails(column2)}
          </InfoColumn>
        )}
      </InfoSection>
      {specSheet && (
        <SpecSheetLink href={specSheet} target="_blank" rel="noopener noreferrer">
          View Spec Sheet
        </SpecSheetLink>
      )}
      {manufacturerLocationImage && (
        <ManufacturerLocation>
          <Typography variant="h6">Manufactured In:</Typography>
          <ManufacturerImage src={manufacturerLocationImage} alt="Manufacturer Location" />
        </ManufacturerLocation>
      )}
    </Container>
  );
};

export default HardwareDetails;
