import React, { useState, useCallback } from 'react';
import axios from 'axios';
import MainOutline from './MainOutline';
import Locate from './Locate';
import Customer from './Customer';
import TagManager from "react-gtm-module";
import Design from './Design';
import useEstimate from '../../hooks/useEstimate';
import useHardware from '../../hooks/useHardware';

const Build = () => {

  const initialFormState = {
    name: '',
    email: '',
    phone: '',
    address: '',
    state: '',
    zip: '',
    electricBill: '',
    systemSize: 0,
    subtotal: 0,
  };

  const initialResponseState = {
    estimateSize: 0,
    qcellAmount: 0,
    qcellSize: 0,
    qcellOffset: 0,
    qcellSubtotal: 0,
    solareverAmount: 0,
    solareverSize: 0,
    solareverOffset: 0,
    solareverSubtotal:0,
    solarProductionMonthly:0,
    projectId: '',
    designId: ''
  }

  const initialErrorFormState = {
    hasEmailAndIsValid: false,
    hasPhoneAndIsValid: false,
  }
  
  const [formState, setFormState] = useState(initialFormState);
  const [responseState, setResponseState] = useState(initialResponseState);
  const [errorFormState, setErrorFormState] = useState(initialErrorFormState);
  const [section, setSection] = useState({ sectionNumber: 0 });
  const [dataLoaded, setDataLoaded] = useState(false);

  TagManager.initialize({'gtmId': "GTM-MD88S8R"});

  const { 
    hardware, 
    updateHardware,
    updateLender,
    updateProduct,
    updateSolutions,
    resetHardwareState
  } = useHardware();

  const { 
    getEstimate, 
    submitting,
    setSubmitting, 
    satelliteImageUrl, 
    loadingTextIndex, 
    loadingTexts, 
    customerErrMesg,
    setCustomerErrMesg,
  } = useEstimate(formState, responseState, setResponseState, setDataLoaded, hardware, updateHardware);  

  async function createCustomer() {
    try{
      setSubmitting(true);
      const data = JSON.stringify({
        "name": formState.name,
        "email": formState.email,
        "phone": formState.phone,
        "address": formState.address,
      });
      
      const config = {
        method: 'post',
        url: process.env.REACT_APP_API_URL + '/estimate',
        headers: { 
          'Content-Type': 'application/json', 
        },
        data : data
      };
      
      await axios(config);

      setSubmitting(false);
      return true;
    } catch(error) {
      console.error("Error creating customer:", error);
      setSubmitting(false);
      setCustomerErrMesg("Error encountered creating customer");
      return false;
    }

  };

  const handleCreateCustomer = async () => {
    setCustomerErrMesg('');
    try {
      await createCustomer();
      return true;
    } catch (error) {
      console.log("Error", error);
      return false;
    }
  };

  const updateFormControl = useCallback((event) => {
    const { id, value } = event.target;
    const updatedFormState = { ...formState, [id]: value };
    const updatedErrorFormState = { ...errorFormState };

    switch (id) {
      case 'electricBill':
        updatedFormState[id] = value.replace(/[^\d]/g, '').slice(0, 4);
        break;
      case 'email':
        updatedErrorFormState.hasEmailAndIsValid = /^[\w.%+-]+@[\w.-]+\.[\w]{2,}$/.test(value);
        break;
      case 'phone':
        const formattedPhoneNumber = formatPhoneNumber(value);
        updatedFormState[id] = formattedPhoneNumber;
        updatedErrorFormState.hasPhoneAndIsValid = formattedPhoneNumber.length === 14;
        break;
      default:
        updatedFormState[id] = value;
    }

    setFormState(updatedFormState);
    setErrorFormState(updatedErrorFormState);
  }, [formState, errorFormState]);

  const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, '');
    if (phoneNumber.length < 4) return phoneNumber;
    if (phoneNumber.length < 7) return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  const addressClick = () => {
    setResponseState(initialResponseState);
    const updatedSectionState = {...section}
    updatedSectionState['sectionNumber'] = 0;
    setSection(updatedSectionState);
  }

  const nextButton = async () => {
    switch (section.sectionNumber) {
      case 0:
        const estimateSuccess = await getEstimate();
        if (estimateSuccess) {
          updateSection(1);
        }
        break;
      case 1:
        updateSection(2);
        // const createSuccess = await handleCreateCustomer();
        // if(createSuccess){
        //   updateSection(2);
        // }
        break;
      default:
        // Handle unexpected section numbers or do nothing
        break;
    }
  };

  

  function updateSection(section) {
    const updatedSectionState = {...section}
    updatedSectionState['sectionNumber'] = section;
    setSection(updatedSectionState);
  }

  const backButton = () => {
    const updatedSectionState = {...section}
    updatedSectionState['sectionNumber'] = section.sectionNumber - 1;

    if(section.sectionNumber === 1){
      resetHardwareState();
      setCustomerErrMesg("");
    }

    setSection(updatedSectionState);

    
  }

  return (
    <MainOutline>
      {section.sectionNumber===0 &&
        <Locate
          formState={formState}
          setFormState={setFormState}
          initialFormState={initialFormState}
          section={section}
          setSection={setSection}
          nextButton={nextButton}
          updateFormControl={updateFormControl}
          submitting={submitting}
          customerErrMesg={customerErrMesg}
          setCustomerErrMesg={setCustomerErrMesg}
          loadingTexts={loadingTexts}
          loadingTextIndex={loadingTextIndex}
          hardware={hardware}
          updateHardware={updateHardware}
          updateSolutions={updateSolutions}
        />
      }
      {section.sectionNumber===1 &&
        <Customer 
          addressClick={addressClick}
          formState={formState}
          updateFormControl={updateFormControl}
          errorFormState={errorFormState}
          nextButton={nextButton}
          dataLoaded={dataLoaded}
          submitting={submitting}
          setSubmitting={setSubmitting}
          customerErrMesg={customerErrMesg}
          backButton={backButton}
          hardware={hardware}
          handleCreateCustomer={handleCreateCustomer}
        />
      }
      {section.sectionNumber===2 &&
        <Design
          addressClick={addressClick}
          formState={formState}
          responseState={responseState}
          nextButton={nextButton}          
          backButton={backButton}
          satelliteImageUrl={satelliteImageUrl}
          hardware={hardware}
          updateHardware={updateHardware}
          updateLender={updateLender}
          updateProduct={updateProduct}
          updateSolutions={updateSolutions}
        />
      }
    </MainOutline>
  );

}

export default Build