import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

// VideoOuterWrapper for the outer container of the video
const VideoOuterWrapper = styled.div`
  height: calc(100% - 80px);
  width: auto;
  margin: 0 auto;
  margin-left: 10px;
  margin-top: 70px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);

  @media screen and (max-width: 900px) {
    display: block;
    /* height: 300px; */
    width: 95%;
    height: auto;
    margin: 0 auto;
    margin-top: 70px;
    max-height: 320px;
    max-width: 380px;
  }
`;

// VideoStyled for styling the actual video element
const VideoStyled = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the video covers the container while maintaining aspect ratio */
  object-position: center; /* Centers the video content in the middle */
  flex-shrink: 0;
`;

const ModelVideo = ({ videoSrc, playbackSpeed = 1.0 }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = playbackSpeed;
    }
  }, [playbackSpeed]);

  return (
    <VideoOuterWrapper>
      <VideoStyled
        ref={videoRef}
        src={videoSrc}
        autoPlay
        loop
        muted
      />
    </VideoOuterWrapper>
  );
};

export default ModelVideo;
