import { useState, useEffect } from "react";

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Only run this effect in the browser
    if (typeof window !== "undefined") {
      const handleResize = () => {
        // Check if matchMedia is supported and then check for mobile view
        const isMobileView = window.matchMedia("(max-width: 900px)").matches;
        setIsMobile(isMobileView);
      };

      // Initial check
      handleResize();

      // Add resize listener
      window.addEventListener("resize", handleResize);

      // Cleanup on unmount
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }

    // Return a no-op cleanup function if window is undefined
    return () => {};
  }, []);

  return isMobile;
};

export default useIsMobile;
