import React from 'react'
import { 
  LunexHeaderLogo, 
  QuoteInnerContainer, 
  QuoteOuterContainer, 
  TopBar 
} from './QuoteElements'
import logo from '../../images/lunexdirectlogo.jpg';
import { Link } from 'react-router-dom';
import FontStyles from '../../fontStyles';

const MainOutline = ({
  children
}) => {

  return (
    <>
      <FontStyles />
      <QuoteOuterContainer>
          <TopBar>
            <Link to='/'>
              <LunexHeaderLogo src={logo} alt={'Lunex Logo Image'}/>
            </Link>
          </TopBar>
          <QuoteInnerContainer>
            {children}
          </QuoteInnerContainer>
          
      </QuoteOuterContainer>
    </>
  )
}

export default MainOutline